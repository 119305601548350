import { useAuth0 } from '@auth0/auth0-react';
import { PlanIcon } from '@marine/icons';
import { Divider } from 'antd';
import Link from 'next/link';
import { FC } from 'react';
import styles from './PricingCard.module.scss';
import { PricingCardProps } from './PricingCard.props';

const PricingCard: FC<PricingCardProps> = ({ showFeatures = true, plan }) => {
  const { isAuthenticated } = useAuth0();

  const { planName, description, features, iconName, price, recurrance } = plan;

  return (
    <div className={styles.container}>
      <div className={styles.containerContent}>
        <div className={styles.containerTitleWrapper}>
          <h3 className={styles.contentTitle}>
            {iconName ? <PlanIcon name={plan?.iconName?.toLowerCase()} styles={{ width: '1.7em' }} /> : null}
            {planName}
          </h3>
        </div>

        <h2 className={styles.contentPrice}>
          <div>{price}</div>

          <p className={styles.contentTime}>{recurrance}</p>
        </h2>
        <p className={styles.contentText}>{description}</p>
      </div>
      {showFeatures && (
        <>
          {!isAuthenticated && (
            <div className={styles.actionButton}>
              {planName?.toLocaleLowerCase().includes('pro') ? (
                <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-block ant-btn-lg ant-btn-special">
                  Try for free
                </Link>
              ) : (
                <Link
                  href={'/register'}
                  className={`ant-btn ant-btn-default ant-btn-lg ant-btn-block ${styles.getStartedButton}`}
                >
                  Get started
                </Link>
              )}
            </div>
          )}
          <Divider style={{ margin: '40px 0 10px' }} />
          <div dangerouslySetInnerHTML={{ __html: features! }} className={styles.featuresContainer} />
        </>
      )}

      <div className={styles.cardFooter}>
        {!isAuthenticated && (
          <div className={styles.footerActionButton}>
            {planName?.toLocaleLowerCase().includes('pro') ? (
              <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-block ant-btn-lg ant-btn-special">
                Try for free
              </Link>
            ) : (
              <Link
                href={'/register'}
                className={`ant-btn ant-btn-default ant-btn-lg ant-btn-block ${styles.getStartedButton}`}
              >
                Get started
              </Link>
            )}
          </div>
        )}
        <Link
          href="/pricing#pricing__plan"
          className={`ant-btn ant-btn-link ant-btn-lg ant-btn-block ${styles.footerButtonMore}`}
        >
          Learn More
        </Link>
      </div>
    </div>
  );
};

export { PricingCard };
